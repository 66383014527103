import React from "react";
import AboutImg from "../img/about.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className=" mt-4 sm:mt-32 p-2 sm:p-0  w-full sm:mx-auto sm:w-3/4">
      <div className="flex  flex-col items-center justify-center sm:flex-row gap-10">
        <div className="w-full">
          <img src={AboutImg} alt="acoounter" />
        </div>
        <div className="w-2/3">
          <div className="flex items-center gap-6">
            <span className="text-2xl sm:text-xl font-medium font-poppins">
              {t("about")}
            </span>

            <div className="w-14 h-[1px] bg-black"></div>
          </div>

          <p className="mt-8 text-xl sm:text-4xl font-medium font-poppins">
            {" "}
            {t("akce")}
          </p>

          <p className="mt-12  font-thin font-poppins">{t("about-text")}</p>

          <Link to="/about">
            <button className="mt-20 border-2 hover:bg-black hover:text-white transition duration-300 font-poppins  border-solid border-black px-10 py-2 font-medium rounded-full">
              {t("continue")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
