import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../i18n/config";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const ContactNav = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("");
  const [isOpen, openMenu] = useState(false);

  const { pathname } = useLocation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
    //Cookies.set("selectedLanguage", e.target.value, { expires: 365 }); // Set cookie to expire in 365 days
  }

  useEffect(() => {
    openMenu(false);
  }, [pathname]);

  return (
    <div className="w-full  bg-[#4499A9]  sm:h-10">
      <div className="flex items-center justify-between sm:justify-end  w-full ">
        <GiHamburgerMenu
          onClick={() => openMenu(!isOpen)}
          className="ml-6 text-xl text-gray-100 hover:text-gray-300 hover:cursor-pointer sm:hidden"
        />

        <div
          tabIndex="0"
          className={` lg:hidden
            ${isOpen ? `block` : `hidden`}
          flex
          
          lg:flex
          lg:flex-row
          lg:static
          lg:shadow-none
          lg:justify-between
          lg:w-full
          items-center
          transition-transform
          z-20
         
     
        flex-col
        gap-4
        absolute
        right-0
        left-0
        top-16 
        bg-[#4499A9] 
        shadow-xl 
        text-center
        p-6
        mt-10
        text-lg 
        `}
        >
          <Link to="/">
            <a className="       hov px-6 py-1 hover:text-neutral-600 transition-colors">
              {t("home")}
            </a>
          </Link>
          <a
            className="px-6 py-1 hover:text-neutral-600 transition-colors"
            href="/#services"
          >
            {t("services")}
          </a>

          <Link to="/">
            <a className="       hov px-6 py-1 hover:text-neutral-600 transition-colors">
              {t("corporate")}
            </a>
          </Link>
          <Link to="/about">
            <a className="lg:mr-auto  px-6 py-1 hover:text-neutral-600 transition-colors">
              {t("about")}
            </a>
          </Link>
          <Link to="/contact">
            <a className=" px-6 py-1 hover:text-neutral-600 transition-colors">
              {t("contact")}
            </a>
          </Link>
        </div>

        <div className=" p-2 sm:p-2 h-full flex flex-col sm:flex-row items-end sm:justify-end gap-2 sm:px-12 sm:gap-10 px-10">
          {/* mail */}
          <div className="flex items-center gap-2">
            <AiOutlineMail className="text-white text-xl" />
            <span className="text-white text-sm sm:text-base font-thin font-poppins">
              info@akcedanismanlik.com
            </span>
            <span className="text-gray-100 hidden sm:block text-xl font-thin">
              {" "}
              |
            </span>
          </div>

          {/* phone */}

          <div className="flex items-center gap-2">
            <BsTelephone className="text-white text-xl font-poppins" />
            <span className="text-white font-poppins text-sm sm:text-base font-thin">
              +90(555) 2509730
            </span>
            <span className="text-gray-100 text-xl font-thin hidden sm:block">
              {" "}
              |
            </span>
          </div>

          <div className="flex gap-3">
            <button
              className={
                "w-8 h-8 rounded-xl border-[1px] border-white  hover:bg-white ease-in duration-150 text-white hover:text-black text-center flex justify-center uppercase items-center " +
                (language === "tr" && "bg-[#df9a2a] text-white")
              }
              onClick={changeLanguage}
              value="tr"
            >
              TR
            </button>
            <button
              className={
                "w-8 h-8 rounded-xl border-[1px] border-white  hover:bg-white ease-in duration-150 text-white hover:text-black text-center flex justify-center uppercase items-center " +
                (language === "en" && "bg-[#df9a2a] text-white")
              }
              onClick={changeLanguage}
              value="en"
            >
              EN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactNav;
