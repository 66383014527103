import React from 'react'
import HeroImg from "../img/hero.jpg"
import { useTranslation } from 'react-i18next';

const Hero = () => {

  const { t } = useTranslation();


  return (
    <div className='w-full h-96 sm:h-[calc(100vh-36px)] '>

        <div className='h-full w-full bg-hero bg-cover  bg-center '>
         
         <div className='gap-10 flex flex-col h-1/2 justify-center ml-8 sm:ml-16'>
            <p className='sm:text-7xl text-3xl font-poppins text-[#4499A9]'> {t("big-title")}</p>
            <p className='text-base sm:text-xl font-thin font-poppins'> {t("small-title")}</p>
         </div>

        </div>
    

    </div>
  )
}

export default Hero