import React from "react";
import ContactImg from "../img/contact.jpg";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { TiLocationOutline } from "react-icons/ti";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="w-full h-36 sm:mt-2 bg-[#0f606d] flex items-center">
        <div className="container mx-auto p-8 flex justify-center sm:justify-start ">
          <span className="text-2xl text-gray-200  font-poppins font-bold">
            {t("contact").toUpperCase()}
          </span>
        </div>
      </div>

      <div className="container mx-auto p-2 sm:p-0  w-full flex  flex-col sm:flex-row sm:gap-20 sm:mt-16 mb-16">
        <div className="">
          <img src={ContactImg} alt="about" />
        </div>

        <div className="flex flex-col mt-10 sm:mt-0 gap-10 sm:w-1/2 p-4">
          <div className="flex items-center gap-6">
            <AiOutlineMail className="text-[#0f606d] text-3xl" />
            <span className=" text-sm sm:text-xl font-thin font-poppins">
              info@akcedanismanlik.com
            </span>
          </div>

          <div className="flex items-center gap-6">
            <BsTelephone className="text-[#0f606d] text-3xl font-poppins" />
            <span className=" font-poppins text-sm sm:text-xl font-thin">
              +90(555) 250 97 30
            </span>
          </div>

          <div className="flex items-center gap-6">
            <TiLocationOutline className="text-[#0f606d] text-3xl font-poppins" />
            <span className=" font-poppins text-sm sm:text-xl font-thin">
              Fatih Mah. Erik Sok. No:46 Bağcılar/İstanbul
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
