import React from 'react'
import About from "../components/About";
import ContactNav from "../components/ContactNav";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Services from "../components/Services";
import WhyUs from "../components/WhyUs";


const Home = () => {
  return (
    <div>
        <Hero />
        <About />
        <WhyUs />
        <Services />
  
    </div>
  )
}

export default Home