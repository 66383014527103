import React from "react";
import Res from "../img/hero.jpg";
import Res1 from "../img/about.jpg";
import Res2 from "../img/img-2.jpg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div id="services" className=" sm:flex w-full  items-center justify-center">
      <section class=" w-full sm:h-screen flex items-center justify-center    bg-fixed  bg-cover bg-world">
        <div class="p-5 text-2xl flex flex-col items-center   sm:h-2/3 w-full sm:w-2/3 mt-8 sm:mt-32 bg-gray-300 backdrop-blur-md   bg-opacity-50 rounded-xl">
          <div className="flex gap-4 items-center">
            <div className="w-10 h-[1px] bg-black"></div>
            <span className="font-poppins text-3xl">{t("services")}</span>
            <div className="w-10 h-[1px] bg-black"></div>
          </div>

          <div className="grid grid-rows-3 sm:grid-cols-3 sm:grid-rows-1 w-full h-full gap-10 mx-auto place-items-start mt-14 sm:mt-32">
            <Link to="/accountment">
              <div className="flex flex-col items-center">
                <div className="w-full h-1/2 bg-white rounded-lg  overflow-hidden">
                  <img
                    className=" cursor-pointer object-cover hover:scale-110 transition duration-500"
                    src={Res2}
                    alt="resim"
                  />
                </div>

                <span>{t("services-3")}</span>
              </div>
            </Link>

            <Link to="/finance">
              <div className="flex flex-col items-center">
                <div className=" bg-white rounded-lg  overflow-hidden">
                  <img
                    className=" cursor-pointer object-fill hover:scale-110 transition duration-500"
                    src={Res1}
                    alt="resim"
                  />
                </div>

                <span>{t("services-2")}</span>
              </div>
            </Link>

            <div className="flex flex-col items-center ">
              <div className="w-full h-1/2 bg-white rounded-lg  overflow-hidden">
                <img
                  className="cursor-pointer object-cover hover:scale-110 transition duration-500"
                  src={Res}
                  alt="resim"
                />
              </div>

              <span>{t("services-1")}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
