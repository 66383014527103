import React from "react";
import { Link } from "react-router-dom";
import Logo from "../img/logo-trim.jpg";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation();

  return (
    <div className="sm:block hidden w-full bg-white  top-0 z-50">
      <div className="container p-1  mx-auto items-center flex justify-around gap-32 ">
        {/* <div  className='w-64 bg-yellow-50 rounded-xl overflow-hidden'> */}
        <Link to="/">
          <img
            src={Logo}
            className="cursor-pointer w-24 bg-yellow-50 "
            alt="logo ofd akcedanismanlik.com"
          />
        </Link>
        {/* </div> */}

        <div className="flex gap-20">
          <a
            className="text-xl font-poppins hover:text-gray-500"
            href="/#services"
          >
            {t("services")}
          </a>
          <a className="text-xl font-poppins hover:text-gray-500 " href="#">
            {t("corporate")}
          </a>
          <Link to="/about">
            <a
              href="/about"
              className="text-xl font-poppins hover:text-gray-500"
            >
              {t("about")}
            </a>
          </Link>
          <Link to="/contact">
            <a className="text-xl font-poppins hover:text-gray-500" href="#">
              {t("contact")}
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
