import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import ContactNav from "./components/ContactNav";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Accountment from "./pages/Accountment";
import Contact from "./pages/Contact";
import Finance from "./pages/Finance";
import Home from "./pages/Home";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

function App() {
  const Layout = () => {
    return (
      <div>
        <ContactNav />
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/finance",
          element: <Finance />,
        },
        {
          path: "/accountment",
          element: <Accountment />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
