import React from 'react'
import Logo from "../img/logo-new.png"
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

  return (
    <div className='w-full bg-[#4499A9]  '>

        <div className='h-full w-full  sm:flex-row item-center  flex flex-col gap-16 p-10'>

          

            <div className='sm:flex-1 hidden  sm:flex  items-start justify-center'>
                <img className='w-1/2 ' src={Logo} alt="logo"/>
            </div>

            <div className='flex-1 flex flex-col gap-4'>
                <span className='font-poppins text-xl text-white '>{t("about")} </span>
                <div>
                    <p className='text-white font-poppins text-xs sm:text-sm font-thin'>
                    {t("about-text")}
                    </p>
                </div>

            </div>

            <div className='flex-1 flex flex-col gap-4'>
            <span className='font-poppins text-xl  text-white'>{t("contact-info")}</span>
                <div className='flex flex-col gap-4'>
                    <div className='flex items-center gap-2'>
                        <AiOutlineMail className='text-white text-xl'/>
                        <span className='text-white font-medium font-poppins'>info@akcedanismanlik.com</span>
                    </div>

                    <div className='flex items-center  gap-2'>
                        <BsTelephone className='text-white text-xl font-poppins'/>
                        <span className='text-white font-poppins font-medium'>+90(555) 2509730</span>
                    </div>
                </div>

            </div>

            
        </div>
        <div className='h-16 w-full bg-gray-700 flex text-sm  items-center justify-center text-white font-thin'>© 2022 -  {t("akce")}</div>
    </div>
  )
}

export default Footer