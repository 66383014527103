import React from "react";
import Img2 from "../img/img-2.jpg";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="w-full h-36 mt-2 grayscale bg-hero bg-cover bg-center flex items-center">
        <div className="container mx-auto p-8 flex justify-center sm:justify-start">
          <span className="text-2xl text-black   font-poppins font-bold">
            {t("about").toUpperCase()}
          </span>
        </div>
      </div>

      <div className="container  mx-auto  w-full  gap-20 mt-10 p-2 sm:p-0 flex  flex-col sm:flex-row sm:gap-20 sm:mt-16 mb-16">
        <div className="">
          <img src={Img2} alt="about" />
        </div>

        <div className="flex flex-col  sm:w-1/2 p-4">
          <h1 className="font-bold font-poppins text-3xl">{t("about")}</h1>

          <p className="font-poppins font-thin mt-10 text-justify ">
            {t("about-text")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
