import React from 'react'
import { BsBuilding } from 'react-icons/bs';
import { CgSandClock } from 'react-icons/cg';
import { VscSettingsGear } from 'react-icons/vsc';
import { GiArrowScope } from 'react-icons/gi';
import { useTranslation } from "react-i18next";

const WhyUs = () => {
    const { t } = useTranslation();

  return (
    <div className='hidden sm:block h-1/2 w-full bg-why bg-cover  bg-center  mt-10'>

    <div className='items-end justify-center  flex w-full flex-col p-10 '>


        <div className='flex text-xl  w-1/2  justify-start font-poppins font-medium  items-center gap-2'>
            <p>{t("why")}</p>
            <div className='w-10 h-[1px] bg-black'></div>
        </div>

        <div className='grid grid-cols-2 grid-rows-2d w-1/2 gap-16 mt-16 ml-96'>

            <div className='flex flex-col gap-4'>
                <BsBuilding size={40} className="text-red-500"/>
                <span className='text-xl font-medium font-poppins'>
               {t("why-1")}
                </span>
                <p className='font-poppins font-thin'>{t("why1-text")}</p>
            </div>

            <div className='flex flex-col gap-4'>
            <GiArrowScope size={40} className="text-red-500"/>
            <span className='text-xl font-medium font-poppins'>
           {t("why-2")}
                </span>
                <p className='font-poppins font-thin'>{t("why2-text")}</p>
            </div>

            <div className='flex flex-col gap-4'>
            <CgSandClock size={40} className="text-red-500"/>
                <span className='text-xl font-medium font-poppins'>
               {t("why-3")}
                </span>
                <p className='font-poppins font-thin'>{t("why3-text")}</p>
            </div>

            <div className='flex flex-col gap-4'>
            <VscSettingsGear size={40} className="text-red-500"/>
            <span className='text-xl font-medium font-poppins'>
           {t("why-4")}
                </span>
                <p className='font-poppins font-thin'>{t("why4-text")}</p>
            </div>

        </div>

    </div>

    

    </div>
  )
}

export default WhyUs