import React from "react";
import { Link } from "react-router-dom";
import ContactNav from "../components/ContactNav";
import Navbar from "../components/Navbar";
import HeroImg from "../img/hero.jpg";
import { useTranslation } from "react-i18next";

const Finance = () => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="w-full h-36 sm:mt-2 bg-[#0f606d] flex items-center">
        <div className="container mx-auto p-8 flex">
          <span className="text-2xl text-gray-200  font-poppins font-bold">
            {t("services-2").toUpperCase()}
          </span>
        </div>
      </div>

      <div className="container  mx-auto   flex flex-col items-center sm:items-start sm:flex-row sm:mt-10">
        <div className="w-full sm:w-80 flex p-8  flex-col ">
          <div className="w-full h-1 bg-red-700"></div>
          <span className="text-xl font-medium mt-6"> Diger Hizmetlerimiz</span>

          <div className="flex flex-col items-start gap-4 mt-6">
            <a
              className="bg-gray-200 w-full py-4 px-8  font-poppins hover:bg-black hover:text-white text-black"
              href="#"
            >
              {t("services-1").toUpperCase()}
            </a>
            <a
              className="bg-gray-200 w-full  py-4 px-8 font-poppins hover:bg-black hover:text-white text-black"
              href="/accountment"
            >
              {t("services-3").toUpperCase()}
            </a>
          </div>
        </div>

        <div className="flex-1 flex flex-col items-center sm:items-start  p-8 ">
          <div className="flex flex-col items-center sm:items-start ">
            <span className="hidden sm:block text-2xl font-poppins font-bold">
              {t("services-2").toUpperCase()}
            </span>
            <img
              className="h-1/2 sm:w-4/5 object-cover sm:mt-4 "
              src={HeroImg}
              alt="dasd"
            />
          </div>

          <div className="w-full sm:w-4/5 mt-10 flex flex-col ">
            <p className="font-poppins">{t("finance-1")}</p>
            <br />
            <b> {t("finance-2")}</b>

            <span className="text-xl mt-8 text-[#0f606d]">
              {t("finance-3")}
            </span>

            <p className="mt-5 font-poppins">{t("finance-4")}</p>

            <br />
            <b> {t("finance-5")}</b>

            <span className="text-xl mt-8 text-[#0f606d]">
              {t("finance-6")}
            </span>

            <p className="mt-5 font-poppins">{t("finance-7")}</p>

            <br />
            <b> {t("finance-8")}</b>

            <span className="text-xl mt-8 text-[#0f606d]">
              {t("finance-9")}
            </span>

            <p className="mt-5 font-poppins">
              <li>{t("finance-10")}</li>
              <li> {t("finance-11")}</li>
              <li> {t("finance-12")}</li>
              <li>{t("finance-13")}</li>
              <li>{t("finance-14")}</li>
              <li> {t("finance-15")}</li>
              <li>{t("finance-16")}</li>
              <li>{t("finance-17")}</li>
              <li>{t("finance-18")}</li>
              <li>{t("finance-19")}</li>
              <li>{t("finance-20")}</li>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
